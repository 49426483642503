import api from './api'; // Import the api module

export class User {
	user_id: string;
	auth_provider: string;
	auth_provider_id: string;
	bio: string;
	created_at: Date;
	email: string;
	email_verified: boolean;
	first_name: string;
	hashed_password: string;
	last_name: string;
	phone_number: string;
	phone_number_verified: boolean;
	previous_usernames: string[];
	profile_picture_url: string;
    cover_picture_url: string;
	standing_updated_at: Date;
	tagline: string;
	updated_at: Date;
	user_standing: string;
	username: string;
    birth_date: Date;
    location: string;
    privacy_settings: {
        public_profile: boolean;
        allow_search: boolean;
        allow_invites: boolean;
    };

	isComplete(): boolean {
		// Ensure all required fields are not empty or null
		return (
			Boolean(this.first_name) &&
			Boolean(this.last_name) &&
			Boolean(this.bio) &&
			Boolean(this.profile_picture_url)
		);
	}

	private static currentUserCache: User | null = null;

	static async fetchCurrentUser(): Promise<User> {
		if (this.currentUserCache) {
				// Return cached user immediately
				return this.currentUserCache;
		}

		try {
			const response = await api.get('/api/v1/user/me');
			// Update cache with new data
			this.currentUserCache = new User(response.data);
			return this.currentUserCache;
		} catch (error) {
			throw error;
		}
	}

    async updateUser(updatedFields: Partial<User>): Promise<User> {
        try {
            const response = await api.put('/api/v1/user/me', updatedFields);
            const updatedUser = new User(response.data);
            // Update cache with updated user data
            User.currentUserCache = updatedUser;
            return updatedUser;
        } catch (error) {
            throw new Error('Failed to update user data');
        }
    }

    async updatePassword(current_password: string, new_password: string): Promise<User> {
        try {
            const response = await api.put('/api/v1/user/me/password', { current_password, new_password });
            return new User(response.data);
        } catch (error) {
            throw new Error('Failed to update password');
        }
    }

    async sendVerificationEmail(): Promise<User> {
        try {
            const response = await api.post('/api/auth/verify');
            return new User(response.data);
        } catch (error) {
            throw new Error('Failed to send verification email');
        }
    }

	constructor(data: any) {
		Object.assign(this, data);
	}

    async setUserWaiver(waiver: string): Promise<User> {
        try {
            const response = await api.post('/api/v1/user/me/waiver', { waiver });
            return new User(response.data);
        } catch (error) {
            throw new Error('Failed to set user waiver');
        }
    }

    async getUserWaiver(): Promise<boolean> {
        try {
            const response = await api.get('/api/v1/user/me/waiver');
            // parse the response, it is a json object with a boolean signed_waiver
            const data = await response.data as { signed_waiver: boolean };
            return data.signed_waiver;
        } catch (error) {
            throw new Error('Failed to get user waiver');
        }
    }


    async uploadUserImage(file: File): Promise<string> {
        // generate upload url
        const uploadResponse = await api.post('/api/v1/user/me/image');
        const { upload_url } = uploadResponse.data;

        // upload file to cloudflare
        const formData = new FormData();
        formData.append('file', file);
    
        const response = await fetch(upload_url, {
          method: 'POST',
          body: formData,
        });
    
        if (!response.ok) {
          throw new Error('Failed to upload user image');
        }

        const data = await response.json() as { 
            success: boolean, 
            messages: { code: number, message: string }[],
            errors: { code: number, message: string }[],
            result: {
                id: string,
                filename: string,
                meta: {
                    user_id: string,
                },
                uploaded: string,
                variants: string[],
                requireSignedURLs: boolean,
            }
        };

        if (!data.success) {
            throw new Error('Failed to upload user image');
        }
        if (data.errors.length > 0) {
            throw new Error(`Failed to upload user image: ${data.errors}`);
        }

        // if there are no variants, then throw an error
        if (data.result.variants.length === 0) {
            throw new Error('No variants returned');
        }
        // if there are no variants, then throw an error
        if (data.result.variants.length === 1) {
            return data.result.variants[0];
        }
        for (const variant of data.result.variants) {
            if (variant.endsWith('public')) {
                return variant;
            }
        }

        // if no public variant, return the last one
        return data.result.variants[data.result.variants.length - 1];
    }

    // async uploadCoverPicture(file: File): Promise<string> {
    // }

    // async uploadProfilePicture(file: File): Promise<string> {
    //     // generate upload url
    //     const uploadResponse = await api.post('/api/v1/user/me/profile_picture');
    //     const { upload_url } = uploadResponse.data;

    //     // upload file to cloudflare
    //     const formData = new FormData();
    //     formData.append('file', file);
    
    //     const response = await fetch(upload_url, {
    //       method: 'POST',
    //       body: formData,
    //     });
    
    //     if (!response.ok) {
    //       throw new Error('Failed to upload profile picture');
    //     }
    
    //     const data = await response.json() as { 
    //         success: boolean, 
    //         messages: { code: number, message: string }[],
    //         errors: { code: number, message: string }[],
    //         result: {
    //             id: string,
    //             filename: string,
    //             meta: {
    //                 user_id: string,
    //             },
    //             uploaded: string,
    //             variants: string[],
    //             requireSignedURLs: boolean,
    //         }
    //     };
    //     if (!data.success) {
    //         throw new Error('Failed to upload profile picture');
    //     }
    //     if (data.errors.length > 0) {
    //         throw new Error(`Failed to upload profile picture: ${data.errors}`);
    //     }

    //     // if there are no variants, then throw an error
    //     if (data.result.variants.length === 0) {
    //         throw new Error('No variants returned');
    //     }
    //     // return the only variant if there is one
    //     // otherwise return the one that has public at the end
    //     // otherwise return the last one
    //     if (data.result.variants.length === 1) {
    //         return data.result.variants[0];
    //     }
    //     for (const variant of data.result.variants) {
    //         if (variant.endsWith('public')) {
    //             return variant;
    //         }
    //     }
    //     // if no public variant, return the last one
    //     return data.result.variants[data.result.variants.length - 1];
    // }

    async signOut(): Promise<void> {
        await api.post('/api/auth/signout');
    }


    async verifyEmail(code: string): Promise<void> {
        const response = await api.get(`/api/auth/verify?code=${code}`);
        if (response.status !== 200) {
            throw new Error('Failed to verify email');
        }
    }

    // Add this static method
    static async signWaiver(waiverData: any): Promise<User> {
        try {
            const response = await api.post('/api/v1/user/me/waiver', waiverData);
            return new User(response.data);
        } catch (error) {
            throw new Error('Failed to submit waiver');
        }
    }
}
