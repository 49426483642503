import React, { useState, useRef, useEffect, useMemo } from 'react';
import { FaUserCircle, FaUserEdit, FaSignOutAlt, FaTimes, FaPlusCircle, FaChevronDown, FaUserPlus, FaFutbol } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import { User } from '../models/user';
import RedirectIfAuthenticated from './RedirectIfAuthenticated';
import Logo from './Logo';

const TopNavBar: React.FC = () => {
	const navigate = useNavigate();
	const [showDropdown, setShowDropdown] = useState(false);
	const [showMobileSettings, setShowMobileSettings] = useState(false);
	const [showCreateDropdown, setShowCreateDropdown] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const mobileSettingsRef = useRef<HTMLDivElement>(null);
	const createDropdownRef = useRef<HTMLDivElement>(null);

	const handleSignOut = async () => {
		try {
			const currentUser = await User.fetchCurrentUser();
			await currentUser.signOut();
		} catch (error) {
			console.error('Sign out failed', error);
		}
		navigate('/signin');
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (createDropdownRef.current && !createDropdownRef.current.contains(event.target as Node)) {
				setShowCreateDropdown(false);
			}
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setShowDropdown(false);
			}
			// ... any other handlers ...
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const closeMobileSettings = () => {
		setShowMobileSettings(false);
	};

	const logoUrl = 'https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/2af30e91-2bd6-4d9b-fb3c-b0b9d8bac800/240x240';

	const logo = useMemo(() => <Logo logoUrl={logoUrl} />, [logoUrl]);

	return (
		<div className="bg-white shadow-md py-3 px-4 md:px-6 flex items-center justify-between relative">
			{RedirectIfAuthenticated && <RedirectIfAuthenticated />}
			<Link to="/dashboard" className="flex items-center cursor-pointer">
				{logo}
				<h1 className="text-xl font-bold text-gray-800">Kickoff</h1>
			</Link>

			{/* Right Side Menu */}
			<div className="flex items-center space-x-4">
				{/* Create Dropdown */}
				<div className="relative" ref={createDropdownRef}>
					<button
						className="flex items-center space-x-2 bg-rose-500 hover:bg-rose-600 text-white px-4 py-2 rounded-full transition duration-300"
						onClick={() => setShowCreateDropdown(!showCreateDropdown)}
					>
						<FaPlusCircle size={20} />
						{/* <span className="align-middle">Create</span> */}
						{/* <FaChevronDown size={14} /> */}
					</button>
					{showCreateDropdown && (
						<div
							className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-hidden"
						>
							<Link
								to="/create-team"
								className="flex items-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300"
							>
								<FaUserPlus className="mr-3 text-indigo-600" size={18} />
								<span className="align-middle">New Team</span>
							</Link>
							<Link
								to="/create-game"
								className="flex items-center px-4 py-3 text-gray-700 hover:bg-rose-50 transition duration-300"
							>
								<FaFutbol className="mr-3 text-rose-600" size={18} />
								<span className="align-middle">New Game</span>
							</Link>
						</div>
					)}
				</div>

				{/* Profile Dropdown */}
				<div className="relative" ref={dropdownRef}>
					<button
						className="flex items-center space-x-2 bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-full transition duration-300"
						onClick={() => setShowDropdown(!showDropdown)}
					>
						<FaUserCircle size={20} />
						<span className="align-middle">Profile</span>
					</button>
					{showDropdown && (
						<div
							className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-hidden"
							style={{ top: '100%' }}
						>
							<Link to="/editprofile" className="flex items-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300">
								<FaUserEdit className="mr-3 text-indigo-600" size={18} />
								<span className="align-middle">Edit Profile</span>
							</Link>
							<button
								onClick={handleSignOut}
								className="w-full flex items-center px-4 py-3 text-gray-700 hover:bg-rose-50 transition duration-300"
							>
								<FaSignOutAlt className="mr-3 text-rose-600" size={18} />
								<span className="align-middle">Sign Out</span>
							</button>
						</div>
					)}
				</div>
			</div>

			{/* Mobile Menu Button */}
			{/* <div className="md:hidden">
				<button
					onClick={() => setShowMobileSettings(true)}
					className="flex items-center space-x-2 bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-full transition duration-300"
				>
					<FaUserCircle size={20} />
					<span className="align-middle">Profile</span>
				</button>
			</div> */}

			{/* Mobile Settings Modal */}
			{showMobileSettings && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-end md:hidden z-50">
					<div className="absolute inset-0" onClick={closeMobileSettings}></div>
					<div ref={mobileSettingsRef} className="bg-white rounded-t-lg w-full p-4 animate-slide-up relative">
						<h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">Settings</h2>
						<div className="space-y-4">
							<button
								onClick={() => {
									navigate('/profile');
									closeMobileSettings();
								}}
								className="w-full flex items-center justify-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300 rounded"
							>
								<FaUserCircle className="mr-3 text-indigo-600" size={20} />
								<span className="align-middle">Profile</span>
							</button>
							<button
								onClick={() => {
									navigate('/editprofile');
									closeMobileSettings();
								}}
								className="w-full flex items-center justify-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300 rounded"
							>
								<FaUserEdit className="mr-3 text-indigo-600" size={20} />
								<span className="align-middle">Edit Profile</span>
							</button>
							<button
								onClick={() => {
									handleSignOut();
									closeMobileSettings();
								}}
								className="w-full flex items-center justify-center px-4 py-3 text-gray-700 hover:bg-rose-50 transition duration-300 rounded"
							>
								<FaSignOutAlt className="mr-3 text-rose-600" size={20} />
								<span className="align-middle">Sign Out</span>
							</button>
							<button
								onClick={closeMobileSettings}
								className="w-full flex items-center justify-center px-4 py-3 text-gray-700 hover:bg-gray-200 transition duration-300 rounded"
							>
								<FaTimes className="mr-3 text-gray-600" size={20} />
								<span className="align-middle">Cancel</span>
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default React.memo(TopNavBar);
